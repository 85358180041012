import type { PageProps } from 'gatsby'
import React from 'react'
import Text, { type TextProps } from 'components/Text'
import EnterpriseFitnessLanding from 'features/enterprise/fitness/components/EnterpriseFitnessLanding'
import * as enterprise from 'libs/enterprise'
import { ReactComponent as LogosSvg } from './images/logos.svg'

type Props = PageProps

export default function EnterpriseSeal(props: Props) {
  const textYellowProps: Partial<TextProps> = {
    color: 'yellow',
    weight: 'semibold',
  }

  return (
    <EnterpriseFitnessLanding
      {...props}
      heroProps={{
        subtext: (
          <Text element="p" flush>
            Meet Bold—your <Text {...textYellowProps}>at home</Text> online exercise program. Bold
            is offered to qualifying SCAN members through One Pass™ at{' '}
            <Text {...textYellowProps}>no cost</Text>.
          </Text>
        ),
        partners: [enterprise.ORCA_KEY],
      }}
      logos={LogosSvg}
      partner={enterprise.SEAL_KEY}
      className="EnterpriseSeal"
    />
  )
}
